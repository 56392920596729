export function initArticleViewIncrement() {
  const scriptInitiator = document.querySelector(".article-view-increment")
  if (scriptInitiator) {
    const url = scriptInitiator.dataset.url
    url &&
      setTimeout(() => {
        fetch(url)
      }, 10000)
  }
}
