import "./css/reset.css"
import "./css/colors.css"
import "./css/layout.css"
import "./css/functional.css"
import "./css/icons.css"
import "./css/content.css"

import "./google-fonts-1686050434657.css"
import "./css/body.css"
import "./css/admin.css"

import "./css/articles.css"
import "./css/events.css"
import "./css/buttons.css"
import "./css/circles.css"
import "./css/footer.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/menu.css"
import "./css/gallery.css"
import "./css/search.css"
import "./css/downloads.css"
import "./css/quote.css"
import "./css/page-teaser.css"
import "./css/memberorganisations.css"
import "./css/publications.css"
import "./css/pagination.css"
import "./css/missions.css"
import "./css/cookiecontrol.css"

import { onReady } from "./js/utils"
import { initGalleries } from "./js/gallery"
import { initArticleViewIncrement } from "./js/article-view-increment"
import { initSubheader } from "./js/sub-nav"

window.htmx = require("htmx.org")

onReady(() => {
  initGalleries()
  initArticleViewIncrement()
  initSubheader()
})
