export function initSubheader() {
  const subheader = document.querySelector(".subheader__title")
  const main = document.querySelector("main")

  if (subheader) {
    const subheaderObserver = new ResizeObserver((containers) => {
      containers.forEach((container) => {
        main.style.minHeight = `${container.contentRect?.height}px`
      })
    })

    subheaderObserver.observe(subheader)
  }
}
